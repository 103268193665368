<template>
  <!-- Main Search Box -->
  <div> 
    <div>

    </div>
    <div class="centered-form">
      <el-select 
        v-model="deploymentName" @change="handleDeploymentChange" class="m-2" placeholder="Choose country"
        style="width: 100px; margin-top: 10px; margin-right: 10px;"
      >
        <el-option
          v-for="item in deploymentList"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        />
      </el-select>
      <el-input
            style="width: 300px; margin-top: 10px;"
            label="Panelist Email"
            v-model="user_email"
            type="text"
            placeholder="Please input panelist's email"         
            @keyup.enter="fetchUserData"
      />
    </div>
    <el-progress v-show="isLoading" :percentage="progress" :color="progressColorFunction"></el-progress>
  </div>

  <!-- Data View -->
  <div v-if="panelistDataLoaded && numOfPanelistIds === 1" style="margin:15px;">
    <!-- Panelist Data View -->
    <PanelistData :panelistData="panelistData"/>

    <!-- Detailed Data Tabs -->
    <div style="margin-top: 15px">
      <el-tabs v-model="activeName" class="demo-tabs" @tab-click="handleTabClick">
        <el-tab-pane label="Rewards" name="Rewards">
          <!-- Reward Data View -->
          <div v-if="activeName==='Rewards'">
            <RewardData :loginUserEmail="loginUserEmail" :panelistData="panelistData" :rewardData="rewardData" :missingRewardTransList="missingRewardTransList"
              @rewardsAddedEvent="handleRewardsAddedEvent"
            />
          </div>
        </el-tab-pane>
        <el-tab-pane label="Redeem" name="Redeem">
          <!-- Reward Data View -->
          <div v-if="activeName==='Redeem'">
            <!--RedeemData :redeemData="redeemData"/-->
            coming soon
          </div>
        </el-tab-pane>        
        <el-tab-pane label="Referral" name="Referral">coming soon</el-tab-pane>
        <el-tab-pane label="Map" name="Map">coming soon</el-tab-pane>
        <el-tab-pane label="Churn" name="Churn">coming soon</el-tab-pane>
      </el-tabs>
    </div>
  </div>
  <div v-if="numOfPanelistIds > 1" class="alert-text">
    <span><el-text style="color: red; font-size: 1em; text-align: center;">This email has more than 1 panelistIDs. Please contact tech team for resolution</el-text></span>
  </div>

  <!-- dialog boxes -->
  <div>
    <el-dialog v-model="dialogErrorVisible" title="Error" @close="dialogErrorVisible = false" width="50%">
      <div style="text-align: left;">
        <p>Error: {{ errorMessage }}</p>
      </div>
      <template v-slot:footer>
        <el-button type="primary" @click="dialogErrorVisible = false">OK</el-button>
      </template>
    </el-dialog>
  </div>
</template>

<style>
  .centered-form {
    display: flex;
    justify-content: center;
    width: 100%;
    margin: 0 auto;
  }
</style>
<style scoped>
  .alert-text {
    color: red;
    font-size: 1.5em; /* Adjust as needed */
    text-align: center;
    margin:50px
}
</style>


<script>
import { ref } from "vue";

import { Amplify } from 'aws-amplify';
import awsconfig from '@/lib/aws-config';

import PanelistData from '@/components/PanelistData.vue'  
import RewardData from '@/components/RewardData.vue'
import RedeemData from '@/components/RedeemData.vue'

import { fetchPanelistData, fetchRewardData, fetchRedeemData } from '@/lib/dataServices.js';

export default {
  props: {
    loginUserEmail: String
  },
  setup(props) {
    const panelistDataLoaded = ref(false);
    const user_email = ref("");
    const panelistData = ref([]);
    const rewardData = ref([]);
    const redeemData = ref([]);
    const dialogErrorVisible = ref(false);
    const isLoading = ref(false);
    const errorMessage = ref("");
    const progress = ref(0);
    const missingRewardTransList = ref([]);
    const numOfPanelistIds = ref(0);

    const deploymentList = [
      {
        value: 'us',
        label: 'US',
      }/*,      
      {
        value: 'uk',
        label: 'UK',
      }*/
    ]

    const deploymentName = ref('us')
    //console.log("awsconfig.us:", awsconfig.us)
    Amplify.configure(awsconfig.us); // default to uk

    console.log("loginUserEmail: " + props.loginUserEmail);

    const handleDeploymentChange = () => {
      console.log("deploymentName: " + deploymentName.value)
      if (deploymentName.value === 'uk') {
        Amplify.configure(awsconfig.uk)
        console.log("awsconfig.uk:", awsconfig.uk)
      } else if (deploymentName.value === 'us') {
        Amplify.configure(awsconfig.us)
        console.log("awsconfig.us:", awsconfig.us)
      }
    }

    const activeName = ref('Rewards')

    const handleTabClick = (tab, event) => {
      console.log("activeName, tab, event", activeName, tab, event)
    }

    const handleRewardsAddedEvent = (eventData) => {
      // Handle the event
      fetchUserData();
    };

    const progressColorFunction = (percentage) => {
      if (percentage <= 10) {
        return 'yellow';
      } else if (percentage <= 40) {
        return 'orange';
      } else {
        return 'green';
      }
    };

    const showErrorDialog = function (error) {
        errorMessage.value = error.message;
        dialogErrorVisible.value = true;
    };

    const checkResult = function (result) {
      // check the response status code to see if the API call was successful or not 
      if (result.cumulativeStatus.responseStatusCode !== "SURVEY_HANDLER_STATUS_SUCCESS") {
        // create an error object with the error message then throw it
        throw new Error("backend error: " + result.cumulativeStatus.responseStatusText);
      }
    }  

    const fetchUserData = async function () {
      try {
        console.log("user_email.value:", user_email.value);

        progress.value = 10; // Set progress to 10% at the beginning
        isLoading.value = true; // Start loading

        var result = {}

        // get panelist data
        result = await fetchPanelistData(user_email.value)
        //console.log("fetchUserData:result1:", result);

        panelistData.value = result.panelistData;
        var createdTime = result.createdTime;
        
        numOfPanelistIds.value = result.panelistData.length;
        var firstPanelistData = result.panelistData[0]
        
        progress.value = 30; 

        // get reward data
        result = await fetchRewardData(firstPanelistData.panelistId, firstPanelistData.deviceId, createdTime)
        //console.log("result2:", result);
        rewardData.value = result.rewardTransList;
        missingRewardTransList.value = result.missingRewardTransList;        

        // Add the 'missingDays' and 'missingRewards' properties to the 'panelistData' object
        panelistData.value.forEach((item) => {
          item.missingDays = missingRewardTransList.value.length;
          item.missingRewards = missingRewardTransList.value.length * 160;
        });

        progress.value = 70; 

        // get rdeem data        
        
        //result = await fetchRedeemData(firstPanelistData.panelistId)
        //console.log("result3:", result);
        redeemData.value = result.redeemTransList;
        
        progress.value = 100; 
        panelistDataLoaded.value = true;

      } catch (error) {
        console.log("Error calling API in fetchUserData()", error);
        showErrorDialog(error);
      } finally {
        isLoading.value = false; // End loading
      }
    };

    return {
      activeName,
      handleTabClick,
      panelistDataLoaded,
      user_email,
      panelistData,
      rewardData,
      missingRewardTransList,
      handleRewardsAddedEvent,
      fetchUserData,
      dialogErrorVisible,
      isLoading,
      errorMessage,
      progress,
      progressColorFunction,
      numOfPanelistIds,
      deploymentName,
      deploymentList,
      handleDeploymentChange
    };
  },
  components: {
    PanelistData,
    RewardData,
    RedeemData
  }
};
</script>


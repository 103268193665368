
import { Auth, API } from 'aws-amplify';
export async function getJWTToken() {
  try {
    const session = await Auth.currentSession();
    const idToken = session.getIdToken().getJwtToken();
    return idToken;
  } catch (error) {
    console.log("Error getting JWT token:", error);
    // Assuming showErrorDialog is a function in the same file or imported from elsewhere
    //showErrorDialog(error);
  }
}

export async function callAmplifyAPI(request, path) {
  try {
    const token = await getJWTToken();
    const result = await API.post("omp-webapp-services-us-prod", path,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: request
      }
    );
    console.log("callAmplifyAPI:request:", request);
    console.log("callAmplifyAPI:result:", result);
    return result;
  } catch (error) {
    console.log("Error calling API in callAmplifyAPI()", error);
    throw(error);
  } 
}